import config from "../config";
const state = () => ({
  interests: {
    loading: true,
    items: [],
  },
  interest_article: {
    loading: true,
    items: [],
    pagination: {},
  },
  artikel_terkait: {
    loading: true,
    items: [],
    pagination: {},
  },
  video_terkait: {
    loading: true,
    items: [],
    pagination: {},
  },
  artikel_lainnya: {
    loading: true,
    loadingShowMore: true,
    items: [],
    totalItem: null,
  },
  articles_tags: {
    loading: true,
    items: [],
    pagination: {},
  },
  articles_tags_infinity: {
    loading: true,
    items: [],
    pagination: {},
  },
});

const mutations = {
  SET_INTERESTS(state, data) {
    state.interests.items = data;
    state.interests.loading = false;
  },
  SET_ARTICLE_INTEREST(state, data) {
    state.interest_article.items = data.data;
    state.interest_article.pagination = data.pagination;
    state.interest_article.loading = false;
  },
  SET_ARTIKEL_TERKAIT(state, data) {
    state.artikel_terkait.items = data.data;
    state.artikel_terkait.pagination = data.pagination;
    state.artikel_terkait.loading = false;
  },
  SET_VIDEO_TERKAIT(state, data) {
    state.video_terkait.items = data.data;
    state.video_terkait.pagination = data.pagination;
    state.video_terkait.loading = false;
  },
  SET_ARTIKEL_LAINNYA(state, data) {
    state.artikel_lainnya.items = data.artikel_lainnya
      ? data.artikel_lainnya
      : state.artikel_lainnya.items;
    state.artikel_lainnya.totalItem = data.totalItem
      ? data.totalItem
      : state.artikel_lainnya.totalItem;
    state.artikel_lainnya.loading = false;
    state.artikel_lainnya.loadingShowMore = data.loadingShowMore
      ? data.loadingShowMore
      : false;
  },
  SET_ARTICLES_TAGS(state, data) {
    state.articles_tags.loading = data.loading;
    state.articles_tags.items = data.items;
    state.articles_tags.pagination = data.pagination;
  },
  SET_ARTICLES_TAGS_INFINITY(state, data) {
    state.articles_tags_infinity.loading = data.loading;
    if (data.pagination.page != state.articles_tags_infinity.pagination.page) {
      data.items.map((article) => {
        state.articles_tags_infinity.items.push(article);
      });
    }
    state.articles_tags_infinity.pagination = data.pagination;
  },
};

const actions = {
  async getInterests({ commit }, data) {
    await this.$axios
      .$get(
        `${config.CORE_SERVICE_API}articles/interests/?sort=publishDate&dir=DESC&limit=1000&&isText=true`
      )
      .then((response) => {
        commit("SET_INTERESTS", response.data);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getArticleByInterest({ commit }, data) {
    await this.$axios
      .$get(
        `${config.CORE_SERVICE_API}articles?sort=publishDate&dir=DESC&isText=true&page=${data.page}&limit=15&category=${data.type}`
      )
      .then((response) => {
        commit("SET_ARTICLE_INTEREST", {
          data: response.data,
          pagination: response.meta,
        });
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getArtikelTerkait({ commit }, data) {
    await this.$axios
      .$get(
        `${config.CORE_SERVICE_API}articles/${data}/related?sort=publishDate&dir=DESC&page=1&limit=6&isText=true`
      )
      .then((response) => {
        commit("SET_ARTIKEL_TERKAIT", {
          data: response.data,
          pagination: response.meta,
        });
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getVideoTerkait({ commit }, data) {
    await this.$axios
      .$get(
        `${config.CORE_SERVICE_API}articles/${data}/related?sort=publishDate&dir=DESC&page=1&limit=10&isText=false`
      )
      .then((response) => {
        commit("SET_VIDEO_TERKAIT", {
          data: response.data,
          pagination: response.meta,
        });
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getArtikelLainnya({ commit }, data) {
    commit("SET_ARTIKEL_LAINNYA", {
      loadingShowMore: true,
    });
    await this.$axios
      .$get(
        `${config.CORE_SERVICE_API}articles?sort=publishDate&dir=DESC&limit=${data.limit}&popular=true&isText=true&articleId=${data.uuid}`
      )
      .then((response) => {
        commit("SET_ARTIKEL_LAINNYA", {
          artikel_lainnya: response.data,
          totalItem: response.meta.total,
        });
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getArticlesByTags({ commit }, data) {
    await this.$axios
      .$get(
        `${config.CORE_SERVICE_API}articles?sort=publishDate&dir=DESC&topics=${
          data.tagsSlug
        }&page=${data.page}&limit=${data.limit ? data.limit : 10}`
      )
      .then((response) => {
        commit("SET_ARTICLES_TAGS", {
          loading: false,
          items: response.data,
          pagination: response.meta,
        });
      })
      .catch((e) => {
        return e.response;
      });
  },

  async getArticlesByTagsInfinity({ commit }, data) {
    commit("SET_ARTICLES_TAGS_INFINITY", {
      ...state().articles_tags_infinity,
      loading: true,
    });
    await this.$axios
      .$get(
        `${config.CORE_SERVICE_API}articles?sort=publishDate&dir=DESC&topics=${
          data.tagsSlug
        }&page=${data.page}&limit=${data.limit ? data.limit : 6}`
      )
      .then((response) => {
        commit("SET_ARTICLES_TAGS_INFINITY", {
          loading: false,
          items: response.data,
          pagination: response.meta,
        });
      })
      .catch((e) => {
        return e.response;
      });
  },
};

export default {
  state,
  mutations,
  actions,
};
