import helper from "/assets/js/helpers.js";
export const state = () => ({
  pageData: {
    title: "",
    desc: "",
    image: "",
    url: "",
    path: "",
    ampPath: ""
  },
});

export const mutations = {
  SET_SEO(state, data) {
    state.pageData.title = data.title;
    state.pageData.desc = helper.cleanHtml(data.desc);
    state.pageData.image = data.image
      ? data.image
      : "https://narasi.tv/icon.png";
    state.pageData.url = data.url;
    state.pageData.path = data.path;
    state.pageData.ampPath = data.ampPath;
  },
};

const actions = {};

const getters = {
  headReturn: (state) => {
    const title =
      state.pageData.title && state.pageData.title.toLowerCase() !== "narasi tv"
        ? state.pageData.title + " | Narasi TV"
        : "Narasi TV";
    const desc =
      state.pageData.desc && state.pageData.desc !== "null"
        ? state.pageData.desc
        : "Indonesia tak tersusun dari batas peta, tapi gerak dan peran kaum muda.";
    const image = state.pageData.image;
    const url = state.pageData.url;
    const path = state.pageData.path;
    const ampPath = state.pageData.ampPath;
    return {
      title: title,
      meta: [
        {
          hid: "description",
          name: "description",
          content: desc,
        },
        {
          hid: "og:type",
          name: "og:type",
          content: "website",
        },
        {
          hid: "og:updated_time",
          name: "og:updated_time",
          content: new Date().toUTCString(),
        },
        {
          hid: "og:title",
          property: "og:title",
          content: title,
        },
        {
          hid: "og:site_name",
          property: "og:site_name",
          content: "Narasi Tv",
        },
        {
          hid: "og:url",
          property: "og:url",
          content: url,
        },
        {
          hid: "og:image",
          property: "og:image",
          itemprop: "image",
          content: image,
        },
        {
          hid: "og:description",
          property: "og:description",
          content: desc,
        },
        {
          hid: "twitter:card",
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          hid: "twitter:title",
          name: "twitter:title",
          content: title,
        },
        {
          hid: "twitter:site",
          name: "twitter:site",
          content: url,
        },
        {
          hid: "twitter:description",
          name: "twitter:description",
          content: desc,
        },
        {
          hid: "twitter:image",
          name: "twitter:image",
          content: image,
        },
        {
          hid: "facebook-domain-verification",
          name: "facebook-domain-verification",
          content: "hlo7c1zmxiuae3dkrkvrw5xbdsgq5n",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: path,
        },
        // {
        //   rel: "amphtml",
        //   href: ampPath,
        // }
      ],
    };
  },
};
export default { state, getters, mutations, actions };
