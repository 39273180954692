const state = () => ({
  stories: {
    loading: true,
    items: []
  },
});

const mutations = {
  SET_STORIES(state, data) {
    state.stories.items = data;
    state.stories.loading = false;
  },
}

const actions = {
  async all({commit}) {
    await this.$axios.$get(`api/stories`).then((response) => {

      let stories = [];
      response.data.map((item, index) => {
        stories.push({
          id: item.id,
          slug: item.slug,
          image: item.image,
          categoryName: item.categoryName,
          createdAt: item.categoryName,
          isRead: item.isRead,
        })
      })

      commit('SET_STORIES', stories)
      return response;
    }).catch((e) => {
      return e.response;
    });
  },
}

export default {
  state,
  mutations,
  actions
};
