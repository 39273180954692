import config from "../config";

const state = () => ({
  campaigns: {
    loading: true,
    items: [],
  },
  quiz: {
    loading: true,
    items: [],
  },
  polling: {
    loading: true,
    items: [],
  },
  suggest: {
    loading: true,
    items: [],
  },
  campaignDetail: {
    loading: true,
    items: {},
  },
  quizDetail: {
    loading: true,
    items: {},
  },
});

const mutations = {
  SET_CAMPAIGN(state, data) {
    state.campaigns.items = data;
    state.campaigns.loading = false;
  },
  SET_QUIZ(state, data) {
    state.quiz.items = data;
    state.quiz.loading = false;
  },
  SET_POLLING(state, data) {
    state.polling.items = data;
    state.polling.loading = false;
  },
  SET_SUGGEST(state, data) {
    state.suggest.items = data;
    state.suggest.loading = false;
  },
  SET_CAMPAIGN_DETAIL(state, data) {
    state.campaignDetail.items = data;
    state.campaignDetail.loading = false;
  },
  SET_QUIZ_DETAIL(state, data) {
    state.quizDetail.items = data;
    state.quizDetail.loading = false;
  },
};

const actions = {
  async campaigns({ commit }, maximal) {
    await this.$axios
      .$get(`${config.KOLABORASI_SERVICE_API}campaigns`)
      .then((response) => {
        let campaigns = response.data;
        commit("SET_CAMPAIGN", campaigns);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },

  async campaignDetail({ commit }, slug) {
    await this.$axios
      .$get(`${config.KOLABORASI_SERVICE_API}campaigns/${slug}`)
      .then((response) => {
        let article = response.data;
        commit("SET_CAMPAIGN_DETAIL", article);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },

  async quiz({ commit }, maximal) {
    await this.$axios
      .$get(`${config.KOLABORASI_SERVICE_API}quizzes`)
      .then((response) => {
        let quizs = response.data;

        commit("SET_QUIZ", quizs);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },

  async quizDetail({ commit }, slug) {
    await this.$axios
      .$get(`${config.KOLABORASI_SERVICE_API}quizzes/${slug}`)
      .then((response) => {
        console.log(response.data);
        let quiz = response.data;
        commit("SET_QUIZ_DETAIL", quiz);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },

  async polling({ commit }, maximal) {
    await this.$axios
      .$get(`/api/kolaborasi/polling?limit=${maximal}`)
      .then((response) => {
        let pollings = [];
        response.data.map((item, index) => {
          pollings.push({
            channelLogo: item.channel.logo,
            channelTitle: item.channel.title,
            channelSlug: item.channel.slug,
            categoryLogo: item.category ? item.category.logo : null,
            categoryTitle: item.category ? item.category.title : null,
            categorySlug: item.category ? item.category.slug : null,
            pollingSlug: item.slug,
            pollingTitle: item.title,
            pollingDesc: item.description,
            thumbnail: {
              large: item.thumbnail.large,
              medium: item.thumbnail.medium,
              small: item.thumbnail.small,
            },
            publishDate: item.publishDate,
            expiredDate: item.expiredDate,
            banner: item.banner,
          });
        });

        commit("SET_POLLING", pollings);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },

  async suggest({ commit }, maximal) {
    await this.$axios
      .$get(`/api/kolaborasi/suggest?limit=${maximal}`)
      .then((response) => {
        let suggests = [];
        response.data.map((item, index) => {
          suggests.push({
            channelLogo: item.channel.logo,
            channelTitle: item.channel.title,
            channelSlug: item.channel.slug,
            categoryLogo: item.category ? item.category.logo : null,
            categoryTitle: item.category ? item.category.title : null,
            categorySlug: item.category ? item.category.slug : null,
            suggestSlug: item.slug,
            suggestTitle: item.title,
            suggestDesc: item.description,
            defaultPlayer: item.contentUrl.default,
            videos: {
              youtube: item.contentUrl.content.filter(
                (item, idx) => item.type === "youtube"
              )[0].url,
              kvp: item.contentUrl.content.filter(
                (item, idx) => item.type === "kvp"
              )[0].url,
              jwPlayer: item.contentUrl.content.filter(
                (item, idx) => item.type === "jwPlayer"
              )[0].url,
              original: item.contentUrl.content.filter(
                (item, idx) => item.type === "original"
              )[0].url,
            },
            thumbnail: {
              large: item.thumbnail.large,
              medium: item.thumbnail.medium,
              small: item.thumbnail.small,
            },
            publishDate: item.publishDate,
            suggestDate: item.suggestDate,
            suggestType: item.suggestType,
            isPaid: item.isPaid,
            banner: item.banner,
          });
        });

        commit("SET_SUGGEST", suggests);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
};

export default {
  state,
  mutations,
  actions,
};
