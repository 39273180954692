import config from "../config";
const state = () => ({
  userTemp: null,
  user: {
    loading: true,
    items: null,
  },
  interests: {
    loading: true,
    items: [],
  },
  university: {
    loading: true,
    items: [],
  },
  cities: {
    loading: true,
    items: [],
  },
});

const mutations = {
  SET_ACCOUNT_TEMP(state, data) {
    state.userTemp = data;
  },
  SET_INTEREST(state, data) {
    state.interests.loading = false;
    state.interests.items = data;
  },
  SET_UNIVERSITY(state, data) {
    state.university.loading = false;
    state.university.items = data;
  },
  SET_CITIES(state, data) {
    state.cities.loading = false;
    state.cities.items = data;
  },
  SET_ACCOUNT(state, data) {
    state.user.loading = false;
    state.user.items = data.items ? data.items : state.user.items;
  },
};

const actions = {
  async getUser({ commit }) {
    await this.$axios
      .$get(`${config.USER_SERVICE_API}/api/users`)
      .then((response) => {
        commit("SET_ACCOUNT", {
          items: response.data,
        });
      })
      .catch((e) => {
        console.log(">>>>>>", e);
      });
  },
  async getListInterests({ commit }) {
    await this.$axios
      .$get(`${config.USER_SERVICE_API}/api/interests`)
      .then((response) => {
        let interests = [];
        response.data.map((int, i) => {
          interests.push({
            label: int.title,
            code: int.id,
          });
        });

        commit("SET_INTEREST", interests);
      })
      .catch((e) => {
        console.log(">>>>>>", e);
      });
  },
  async getListUniversity({ commit }) {
    await this.$axios
      .$get(`${config.USER_SERVICE_API}/api/universities?limit=1000&page=1`)
      .then((response) => {
        let universities = [];
        response.data.map((university, i) => {
          universities.push({
            label: university.name,
            code: university.id,
          });
        });

        commit("SET_UNIVERSITY", universities);
      })
      .catch((e) => {
        console.log(">>>>>>", e);
      });
  },
  async getLisCities({ commit }) {
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}cities`)
      .then((response) => {
        let cities = [];
        response.map((city, i) => {
          cities.push({
            label: city.name,
            code: city.id,
          });
        });

        commit("SET_CITIES", cities);
      })
      .catch((e) => {
        console.log(">>>>>>", e);
      });
  },
};

export default {
  state,
  mutations,
  actions,
};
