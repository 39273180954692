import config from "../config";
export const state = () => ({
  data: {
    isLoading: false,
    message: null,
    isValid: false,
    detail: null,
  },
});

export const mutations = {
  SET_VOUCHER(state, data) {
    state.data = data;
  },
};

export const actions = {
  async checkVoucher({ commit }, data) {
    commit("SET_VOUCHER", {
      isLoading: true,
      message: null,
      isValid: false,
      detail: null,
    });

    if (data.voucher) {
      let api = this.$axios.create();
      api
        .$post(`${config.PAYMENT_SERVICE_API}api/voucher/check`, data)
        .then((response) => {
          commit("SET_VOUCHER", {
            isLoading: false,
            message: response.message,
            isValid: true,
            detail: response.data,
          });
        })
        .catch((e) => {
          commit("SET_VOUCHER", {
            isLoading: false,
            message: e.response.data.message,
            isValid: false,
            detail: null,
          });
        });
    } else {
      commit("SET_VOUCHER", {
        isLoading: false,
        message: null,
        isValid: false,
        detail: null,
      });
    }
  },
};

export default {
  state,
  mutations,
  actions,
};
