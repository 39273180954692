import config from "../config";
const state = () => ({
  autoPlay: true,
  detail: {
    items: null,
    loading: true
  },
  comments: {
    items: null,
    loading: true
  }
});

const mutations = {
  SET_DETAIL(state, data) {
    state.detail.items = data;
    state.detail.loading = false;
  },
  SET_COMMENTS(state, data) {
    state.comments.items = data;
    state.comments.loading = false;
  },
  SET_AUTOPLAY(state, data) {
    state.autoPlay = !state.autoPlay;
  },
}

const actions = {
  async getDetail({commit}, data) {
    await this.$axios.$get(`${config.CORE_SERVICE_API}articles/${data.channel}/${data.slug}`).then((item) => {
      let articles = {
        channelLogo: item.data.channel.logo,
        channelTitle: item.data.channel.title,
        channelSlug: item.data.channel.slug,
        categoryLogo: item.data.category ? item.data.category.logo : null,
        categoryTitle: item.data.category ? item.data.category.title : null,
        categorySlug: item.data.category ? item.data.category.slug : null,
        articleSlug: item.data.slug,
        articleTitle: item.data.title,
        articleShort: item.data.short,
        articleDescription: item.data.content,
        defaultPlayer: item.data.contentUrl.default,
        videos: {
          youtube: (item.data.contentUrl.content.filter((data, idx) => data.type === 'youtube').length > 0) ? item.data.contentUrl.content.filter((data, idx) => data.type === 'youtube')[0].url : null,
          kvp: (item.data.contentUrl.content.filter((data, idx) => data.type === 'kvp').length > 0) ? item.data.contentUrl.content.filter((data, idx) => data.type === 'kvp')[0].url : null,
          jwPlayer: (item.data.contentUrl.content.filter((data, idx) => data.type === 'jwPlayer').length > 0) ? item.data.contentUrl.content.filter((data, idx) => data.type === 'jwPlayer')[0].url : null,
          original: (item.data.contentUrl.content.filter((data, idx) => data.type === 'original').length > 0) ? item.data.contentUrl.content.filter((data, idx) => data.type === 'original')[0].url : null,
        },
        thumbnail: {
          large: item.data.thumbnail.large,
          medium: item.data.thumbnail.medium,
          small: item.data.thumbnail.small,
        },
        publishDate: item.data.publishDate,
        createdAt: item.data.createdAt,
        isVideo: item.data.isVideo,
        videoTime: item.data.timeVideo,
        banner: item.banner,
      };

      commit('SET_DETAIL', articles);
    }).catch((e) => {
      return e.response;
    });
  },
  async comments({commit}, data) {
    await this.$axios.$get(`api/articles/comments/${data.slug}?page=${data.page}&limit=${data.limit}`).then((response) => {
      let comments = [];
      response.data.map((item, i) => {
        let userLikes = [];
        let commentReply= [];

        item.userLikes.map((userLike, i) => {
          userLikes.push({
            id: userLike.id,
            photo: userLike.photo,
            fullName: userLike.fullName
          })
        })

        item.commentReply.map((reply, i) => {
          let childUserLikes = [];
          reply.userLikes.map((like, i) => {
            childUserLikes.push({
              id: like.id,
              photo: like.photo,
              fullName: like.fullName
            })
          })

          commentReply.push({
            uuid: reply.uuid,
            articleSlug: reply.articleSlug,
            comment: reply.comment,
            user: {
              id: reply.user.id,
              photo: reply.user.photo,
              fullName: reply.user.fullName
            },
            createdAt: reply.createdAt,
            userLikes: childUserLikes
          })
        })

        comments.push({
          uuid: item.uuid,
          articleSlug: item.articleSlug,
          comment: item.comment,
          user: {
            id: item.user.id,
            photo: item.user.photo,
            fullName: item.user.fullName
          },
          createdAt: item.createdAt,
          userLikes: userLikes,
          commentReply: commentReply
        })
      });

      commit('SET_COMMENTS', comments);
    }).catch((e) => {
      return e.response;
    });
  },
}

export default {
  state,
  mutations,
  actions
};
