import config from "../config";

const state = () => ({
  karirs: {
    loading: true,
    items: [],
  },
  karir: {
    loading: true,
    items: {},
  },
  tow: {
    loading: true,
    items: [],
  },
  teams: {
    loading: true,
    items: [],
  },
  locations: {
    loading: true,
    items: [],
  },
});

const mutations = {
  SET_KARIRS(state, data) {
    state.karirs.loading = false;
    state.karirs.items = data;
  },
  SET_KARIR(state, data) {
    state.karir.loading = false;
    state.karir.items = data;
  },
  SET_TOW(state, data) {
    state.tow.loading = false;
    state.tow.items = data;
  },
  SET_TEAMS(state, data) {
    state.teams.loading = false;
    state.teams.items = data;
  },
  SET_LOCATIONS(state, data) {
    state.locations.loading = false;
    state.locations.items = data;
  },
};

const actions = {
  async getKarirs({ commit }, data) {
    await this.$axios
      .$get(
        `${config.CORE_SERVICE_API}careers?typeOfWork=${
          data ? data.typeOfWork : ""
        }&team=${data ? data.departemen : ""}&search=${data ? data.search : ""}`
      )
      .then((response) => {
        commit("SET_KARIRS", response.data);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getKarir({ commit }, id) {
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}careers/${id}`)
      .then((response) => {
        commit("SET_KARIR", response.data);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getTOW({ commit }, id) {
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}careers/typeOfWork`)
      .then((response) => {
        commit("SET_TOW", response.data);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getTeams({ commit }, id) {
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}careers/teams`)
      .then((response) => {
        commit("SET_TEAMS", response.data);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getLocations({ commit }, id) {
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}careers/locations`)
      .then((response) => {
        commit("SET_LOCATIONS", response.data);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
};

export default {
  state,
  mutations,
  actions,
};
