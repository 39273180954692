import storeUser from "./storeUser";
import articles from "./articles";
import stories from "./stories";
import program from "./program";
import detailArticles from "./detailArticles";
import live from "./live";
import kolaborasi from "./kolaborasi";
import config from "./config";
import channel from "./channel";
import account from "./account";
import seo from "./seo";
import seoWithAmp from "./seoWithAmp";
import voucher from "./voucher"
import suaraPenentu from "./suaraPenentu";

export const modules = {
  storeUser,
  articles,
  stories,
  program,
  detailArticles,
  live,
  kolaborasi,
  config,
  channel,
  account,
  seo,
  seoWithAmp,
  voucher,
  suaraPenentu
};
