const state = () => ({
  session: null
});

const mutations = {
  setSession(state, data) {
    state.session = data;
  },
};

export default {
  state,
  mutations
};
