import config from "../config";
import moment from "moment";

const state = ($moment) => ({
  filter: {
    loading: true,
    bulan: moment(new Date()).local().month() + 1,
    tahun: moment(new Date()).local().year(),
    kota: {
      provKode: "c51ce410c124a10e0db5e4b97fc2af39",
      provNama: "DKI JAKARTA",
      kabkoKode: "58a2fc6ed39fd083f55d4182bf88826d",
      kabkoNama: "KOTA JAKARTA",
      slug: "kota-jakarta",
    },
  },
  provinsi: {
    loading: true,
    items: [],
  },
  kota: {
    loading: true,
    items: [],
  },
  one_kota: {
    loading: true,
    items: {},
  },
  jadwal: {
    loading: true,
    items: {},
    tanggal: {},
  },
  jadwalBulanan: {
    loading: true,
    items: [],
  },
  surat: {
    loading: true,
    items: [],
  },
  oneAyat: {
    loading: true,
    items: {},
  },
  ayat: {
    loading: true,
    items: {},
    listAyat: [],
    surat: {},
    total: 0
  },
  tafsir: {
    loading: true,
    items: [],
    surah: {},
  },
});

const mutations = {
  SET_FILTER(state, data) {
    state.filter.loading = false;
    state.filter.bulan = data.bulan ? data.bulan : state.filter.bulan;
    state.filter.tahun = data.tahun ? data.tahun : state.filter.tahun;
    state.filter.kota = data.kota ? data.kota : state.filter.kota;
  },
  SET_PROVINSI(state, data) {
    state.provinsi.loading = false;
    state.provinsi.items = data;
  },
  SET_KOTA(state, data) {
    state.kota.loading = false;
    state.kota.items = data;
  },
  SET_ONE_KOTA(state, data) {
    state.one_kota.loading = false;
    state.one_kota.items = data;
  },
  SET_JADWAL(state, data) {
    state.jadwal.loading = false;
    state.jadwal.items = data.dataSalat;
    state.jadwal.tanggal = data.tanggal;
  },
  SET_JADWAL_BULANAN(state, data) {
    state.jadwalBulanan.loading = false;
    state.jadwalBulanan.items = data;
  },
  SET_SURAT(state, data) {
    state.surat.loading = false;
    state.surat.items = data;
  },
  SET_ONE_AYAT(state, data) {
    state.oneAyat.loading = false;
    state.oneAyat.items = data[0];
  },
  SET_AYAT(state, data) {
    state.ayat.loading = false;
    state.ayat.total = data.dataAyat.length;
    state.ayat.items = data.dataAyat;
    state.ayat.surat = data.dataAyat.surah;
    if (data.firstLoad) {
      state.ayat.listAyat = data.dataAyat.data;
    } else {
      state.ayat.listAyat = data.dataAyat.data
        ? state.ayat.listAyat.length > 0
          ? state.ayat.listAyat
              .concat(data.dataAyat.data)
              .sort((a, b) => (a.no_ayat > b.no_ayat ? 1 : -1))
          : data.dataAyat.data
        : state.ayat.listAyat;
    }
    state.ayat.listAyat = [
      ...new Map(state.ayat.listAyat.map((v) => [v.ayat_id, v])).values(),
    ];
  },
  SET_TAFSIR(state, data) {
    state.tafsir.loading = false;
    state.tafsir.items = data.tafsir;
    state.tafsir.surah = data.surah;
  },
};

const actions = {
  async getProvinsi({ commit }, data) {
    await this.$axios
      .$get(
        `${config.BASE_URL_API_KEMENAG}/getShalatProv?param_token=${config.TOKEN_KEMENAG}`
      )
      .then((response) => {
        commit("SET_PROVINSI", response);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getKota({ commit }, data) {
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}ramadhan`)
      .then((response) => {
        commit("SET_KOTA", response);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getOneKota({ commit }, data) {
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}ramadhan/${data}`)
      .then((response) => {
        commit("SET_ONE_KOTA", response);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getJadwal({ commit }, data) {
    this.$axios
      .$post(`${config.CORE_SERVICE_API}ramadhan`, {
        year: data.tahun.toString(),
        month: data.bulan,
        prov: data.provinsi,
        city: data.kota,
      })
      .then((response) => {
        let pickedDate = this.$moment(data.date).format("YYYY-MM-DD");
        for (const key in response.data) {
          if (key == pickedDate) {

            let data = {
              imsak: response.data[key].imsak,
              subuh: response.data[key].subuh,
              dzuhur: response.data[key].dzuhur,
              ashar: response.data[key].ashar,
              maghrib: response.data[key].maghrib,
              isya: response.data[key].isya,
            };
            commit("SET_JADWAL", {
              dataSalat: data,
              tanggal: response.data[key].tanggal,
            });
          }
        }
        commit("SET_JADWAL_BULANAN", response.data);
      })
      .catch((e) => {
        console.log(e.response);
      });
  },
  async getSurat({ commit }) {
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}ramadhan/surah`)
      // .$get(`https://quranapi.lpmqkemenag.id/api/v1/surah/0/114`)
      .then((response) => {
        commit("SET_SURAT", response);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getAyat({ commit }, data) {
    await this.$axios
      .$get(`${data.link}`)
      .then((response) => {
        commit("SET_AYAT", {
          dataAyat: response,
          firstLoad: data.firstLoad ? data.firstLoad : false,
        });
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getOneAyat({ commit }, id) {
    await this.$axios
      .$get(`https://quranapi.lpmqkemenag.id/api/v1/ayatdetail/${id}`)
      .then((response) => {
        commit("SET_ONE_AYAT", response.data);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getTafsir({ commit }, data) {
    await this.$axios
      // .$get(`https://quranapi.lpmqkemenag.id/api/v1/tafsirbyayat/${id}`)
      .$get(
        `${config.CORE_SERVICE_API}ramadhan/surah/${data.surat}`
      )
      .then((response) => {
        commit("SET_TAFSIR", response);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
};

export default {
  state,
  mutations,
  actions,
};
