import config from "../config";

const state = () => ({
  menus: {
    loading: true,
    items: [],
  },
  highlights: {
    loading: true,
    items: [],
  },
  programs: {
    loading: true,
    items: [],
  },
  artikel_terpopuler: {
    loading: true,
    items: [],
  },
  video_terpopuler: {
    loading: true,
    items: [],
  },
  pinned_article: {
    loading: true,
    items: {},
  },
  articles_navbar: {
    loading: true,
    items: [],
  },
  interests: {
    loading: true,
    items: [],
  },
  artikel_terkini: {
    loading: true,
    loadingShowMore: true,
    pages: null,
    items: [],
  },
});

const mutations = {
  SET_MENUS(state, data) {
    state.menus.loading = false;
    state.menus.items = data;
  },
  SET_HIGHLIGHTS(state, data) {
    state.highlights.loading = false;
    state.highlights.items = data;
  },
  SET_PROGRAMS(state, data) {
    state.programs.loading = false;
    state.programs.items = data;
  },
  SET_ARTIKEL_TERPOPULER(state, data) {
    state.artikel_terpopuler.loading = false;
    state.artikel_terpopuler.items = data;
  },
  SET_VIDEO_TERPOPULER(state, data) {
    state.video_terpopuler.loading = false;
    state.video_terpopuler.items = data;
  },
  SET_PINNED_ARTICLE(state, data) {
    state.pinned_article.loading = false;
    state.pinned_article.items = data;
  },
  SET_ARTICLE_NAVBAR(state, data) {
    if (data.slug) {
      state.articles_navbar.items[data.slug] = data.data;
    } else {
      state.articles_navbar.items = data.data;
    }
    state.articles_navbar.loading = data.loading;
  },
  SET_INTERESTS(state, data) {
    state.interests.loading = false;
    state.interests.items = data;
  },
  SET_ARTIKEL_TERKINI(state, data) {
    if (data.firstLoad) {
      state.artikel_terkini.items = data.articles;
    } else {
      state.artikel_terkini.items = data.articles
        ? state.artikel_terkini.items.length > 0
          ? state.artikel_terkini.items.concat(data.articles)
          : data.articles
        : state.artikel_terkini.items;
    }

    state.artikel_terkini.pages = data.pages
      ? data.pages
      : state.artikel_terkini.pages;
    state.artikel_terkini.loading = false;
    state.artikel_terkini.loadingShowMore = data.loadingShowMore
      ? data.loadingShowMore
      : false;
  },
};

const actions = {
  async getMenus({ commit }, data) {
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}options/navbar`)
      .then((response) => {
        commit("SET_MENUS", response);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getHighlights({ commit }, data) {
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}options/navbar/highlights`)
      .then((response) => {
        commit("SET_HIGHLIGHTS", response);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getPrograms({ commit }, data) {
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}programs`)
      .then((response) => {
        commit("SET_PROGRAMS", response.data);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getArtikelTerpopuler({ commit }, data) {
    await this.$axios
      .$get(
        `${config.CORE_SERVICE_API}articles?sort=publishDate&dir=DESC&limit=5&popular=true&isText=true`
      )
      .then((response) => {
        commit("SET_ARTIKEL_TERPOPULER", response.data);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getVideoTerpopuler({ commit }, data) {
    await this.$axios
      .$get(
        `${config.CORE_SERVICE_API}articles?sort=publishDate&dir=DESC&limit=5&popular=true&isVideo=true`
      )
      .then((response) => {
        commit("SET_VIDEO_TERPOPULER", response.data);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getPinnedArticle({ commit }, slug) {
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}articles/navbar/${slug}/pinned`)
      .then((response) => {
        commit("SET_PINNED_ARTICLE", response.data);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getArticleNavbar({ commit }, slug) {
    commit("SET_ARTICLE_NAVBAR", {
      data: [],
      slug: slug,
      loading: true,
    });
    await this.$axios
      .$get(
        slug == "program"
          ? `${config.CORE_SERVICE_API}programs/videos/all?limit=4&page=1`
          : `${config.CORE_SERVICE_API}options/navbar/${slug}/videos?limit=4`
      )
      .then((response) => {
        commit("SET_ARTICLE_NAVBAR", {
          data: response.data,
          slug: slug,
          loading: false,
        });
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getInterests({ commit }, slug) {
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}options/navbar/${slug}/interests`)
      .then((response) => {
        commit("SET_INTERESTS", response);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async setArtikelTerkini({ commit }, data) {
    commit("SET_ARTIKEL_TERKINI", {
      loadingShowMore: true,
      articles: [],
    });
    await this.$axios
      .$get(
        data.interestSlug != "semua"
          ? `${
              config.CORE_SERVICE_API
            }articles?sort=publishDate&dir=DESC&limit=10&category=${
              data.interestSlug
            }&page=${data.page ? data.page : 1}`
          : `${config.CORE_SERVICE_API}articles/navbar/${data.slug}?page=${
              data.page ? data.page : 1
            }`
      )
      .then((response) => {
        commit("SET_ARTIKEL_TERKINI", {
          articles: response.data,
          pages: response.meta.pages,
          firstLoad: data.firstLoad ? true : false,
        });
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
};

export default {
  state,
  mutations,
  actions,
};
