import config from "../config";

export const state = () => ({
  eventCategories: {
    loading: true,
    items: [],
  },
  eventCarousel: {
    loading: true,
    items: [],
  },
  eventRelated: {
    loading: true,
    items: [],
  },
  eventDataAll: {
    loading: true,
    items: [],
    statusName: "",
  },
  eventsNarasi: {
    loading: true,
    items: [],
    pagination: {},
  },
  eventDataOngoing: {
    loading: true,
    items: [],
    pagination: {},
  },
  eventDataNextEvent: {
    loading: true,
    items: [],
    pagination: {},
  },
  eventDataRecommend: {
    loading: true,
    items: [],
    pagination: {},
  },
  eventDataByCategory: {
    loading: true,
    loadingShowMore: true,
    items: {},
    totalItem: null,
    pagination: {},
  },
  dataLinimasa: {
    loading: true,
    items: [],
  },
  dataDetailLinimasa: {
    loading: true,
    loadingTwo: false,
    items: {},
  },
  dataDetailEvent: {
    loading: true,
    items: {},
  },
});

export const mutations = {
  SET_CATEGORIES(state, data) {
    state.eventCategories.items = data;
    state.eventCategories.loading = false;
  },
  SET_CAROUSEL(state, data) {
    state.eventCarousel.items = data;
    state.eventCarousel.loading = false;
  },
  SET_DATA_DETAIL_EVENT(state, data) {
    state.dataDetailEvent.items = data;
    state.dataDetailEvent.loading = false;
  },
  SET_EVENT_DATA_ONGOING(state, data) {
    state.eventDataOngoing.items = data.data;
    state.eventDataOngoing.pagination = data.pagination;
    state.eventDataOngoing.loading = false;
  },
  SET_EVENTS_NARASI(state, data) {
    state.eventsNarasi.items = data.data;
    state.eventsNarasi.pagination = data.pagination;
    state.eventsNarasi.loading = false;
  },
  SET_EVENT_DATA_NEXT_EVENT(state, data) {
    state.eventDataNextEvent.items = data.data;
    state.eventDataNextEvent.pagination = data.pagination;
    state.eventDataNextEvent.loading = false;
  },
  SET_EVENT_DATA_RECOMMEND(state, data) {
    state.eventDataRecommend.items = data.data;
    state.eventDataRecommend.pagination = data.pagination;
    state.eventDataRecommend.loading = false;
  },
  SET_EVENT_DATA_BY_CATEGORY(state, data) {
    state.eventDataByCategory.items = data.data
      ? data.data
      : state.eventDataByCategory.items;
    state.eventDataByCategory.totalItem = data.totalItem
      ? data.totalItem
      : state.eventDataByCategory.totalItem;
    state.eventDataByCategory.loading = false;
    state.eventDataByCategory.loadingShowMore = data.loadingShowMore
      ? data.loadingShowMore
      : false;
    state.eventDataByCategory.pagination = data.pagination;
  },
  SET_DATA_LINIMASA(state, data) {
    state.dataLinimasa.items = data.linimasa;
    state.dataLinimasa.loading = false;
    state.dataLinimasa.loadingTwo = data.loadingTwo ? data.loadingTwo : false;
  },
};

export const actions = {
  async setCategories({ commit }) {
    await this.$axios
      .$get(`${config.EVENT_SERVICE_API}interests?populer=true`)
      .then((response) => {
        let categories = [];
        response.data.map((item, index) => {
          categories.push({
            id: item.id,
            title: item.name,
            slug: item.slug,
            logo: item.logo,
            createdAt: item.createdAt,
          });
        });
        commit("SET_CATEGORIES", categories);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async carousel({ commit }) {
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}event/banners`)
      .then((response) => {
        let carousel = response.data;
        commit("SET_CAROUSEL", carousel);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async dataEventsNarasi({ commit }, data) {
    await this.$axios
      .$get(
        `${config.EVENT_SERVICE_API}events?dir=DESC&page=${
          data ? data : 1
        }&limit=8`
      )
      .then((response) => {
        let data = {
          data: response.data,
          pagination: response.meta,
        };
        commit("SET_EVENTS_NARASI", data);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async dataEventOngoing({ commit }, data) {
    await this.$axios
      .$get(`${config.EVENT_SERVICE_API}events/current?page=${data ? data : 1}`)
      .then((response) => {
        let data = {
          data: response.data,
          pagination: response.meta,
        };
        commit("SET_EVENT_DATA_ONGOING", data);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async dataEventNext({ commit }, data) {
    await this.$axios
      .$get(
        `${config.EVENT_SERVICE_API}events/coming-soon?page=${data ? data : 1}`
      )
      .then((response) => {
        let data = {
          data: response.data,
          pagination: response.meta,
        };
        commit("SET_EVENT_DATA_NEXT_EVENT", data);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async dataEventRecommend({ commit }, data) {
    await this.$axios
      .$get(
        `${config.EVENT_SERVICE_API}events?categories=${data.categories}&page=1&eventId=${data.eventId}&timeline=true`
      )
      .then((response) => {
        let data = {
          data: response.data,
          pagination: response.meta,
        };
        commit("SET_EVENT_DATA_RECOMMEND", data);
        return response;
      })
      .catch((e) => {
        console.log(e);
        return e.response;
      });
  },
  async dataEventByCategory({ commit }, data) {
    commit("SET_EVENT_DATA_BY_CATEGORY", {
      loadingShowMore: true,
    });
    await this.$axios
      .$get(
        `${config.EVENT_SERVICE_API}events?limit=${
          data.limit ? data.limit : 10
        }${data.slug === "" ? "" : `&interests_slug=${data.slug}`}${
          data.page ? `&page=${data.page}` : 1
        }`
      )
      .then((response) => {
        commit("SET_EVENT_DATA_BY_CATEGORY", {
          data: response.data,
          totalItem: response.meta.total,
          pagination: response.meta,
        });
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async dataLinimasaNarasi({ commit }, year) {
    let data = {
      linimasa: [],
      loadingTwo: true,
    };
    commit("SET_DATA_LINIMASA", data);
    await this.$axios
      .$get(`${config.EVENT_SERVICE_API}events/year/${year}`)
      .then((response) => {
        let data = {
          linimasa: response.data,
        };
        commit("SET_DATA_LINIMASA", data);
        return response;
      })
      .catch((e) => {
        window.$nuxt.$store.commit("config/setLoading", false);
        return e.response;
      });
  },
  async dataDetailEvent({ commit }, slug) {
    await this.$axios
      .$get(`${config.EVENT_SERVICE_API}events/detail/${slug}`)
      .then((response) => {
        let detail_event = response.data;
        commit("SET_DATA_DETAIL_EVENT", detail_event);
        return response;
      })
      .catch((e) => {
        let data = {
          linimasa: [],
        };
        commit("SET_DATA_LINIMASA", data);
        return e.response;
      });
  },
};
