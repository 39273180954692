import config from "../config";
const state = () => ({
  detail: {
    items: null,
    loading: true,
  },
  schedule: {
    items: null,
    loading: true,
  },
  vod: {
    loading: true,
    loadingShowMore: true,
    items: [],
    totalItem: null,
  },
  live: {
    loading: true,
    loadingShowMore: true,
    items: [],
    totalItem: null,
  },
});

const mutations = {
  SET_DETAIL(state, data) {
    state.detail.items = data;
    state.detail.loading = false;
  },
  SET_SCHEDULES(state, data) {
    state.schedule.items = data;
    state.schedule.loading = false;
  },
  SET_VOD(state, data) {
    state.vod.items = data.items;
    state.vod.totalItem = data.totalItem;
    state.vod.loading = false;
    state.vod.loadingShowMore = data.loadingShowMore ? data.loadingShowMore : false;
  },
  SET_LIVE(state, data) {
    state.live.items = data.items;
    state.live.totalItem = data.totalItem;
    state.live.loading = false;
    state.live.loadingShowMore = data.loadingShowMore ? data.loadingShowMore : false;
  },
};

const actions = {
  async getLiveStreaming({ commit }, data) {
    commit("SET_LIVE", {
      loadingShowMore: true,
      loading: true,
      items: state().live.items,
      totalItem: state().live.items,
    });
    
    await this.$axios.$get(`${config.CORE_SERVICE_API}livestreaming/active?limit=5&page=${data.pageLive}&slug=${data.slug}`).then((response) => {
      let lives = [];
      response.items.map((item, index) => {
        lives.push({
          id: item.id,
          title: item.title,
          slug: item.slug,
          banner: item.banner,
          thumbnail: item.thumbnail,
          channel: item.channel.title,
          interest: item.interest.title,
          polling: item.polling,
          startDate: +item.startTime,
          endDate: +item.endTime,
          canChatting: item.liveChat,
          requiredLogin: true,
          description: item.description,
          mediaIdPlayer: item.mediaIdPlayer,
          refEventId: item.refEventId,
          embedUrl: item.embedUrl
        });
      });

      commit("SET_LIVE", {
        items: lives,
        totalItem: response.meta.totalItems,
      });

    }).catch((e) => {
      return e.response;
    });
  },

  async getVodLiveStreaming({ commit }, data) {
    // commit("SET_VOD", {
    //   loadingShowMore: true,
    //   loading: true,
    //   items: state().vod.items,
    //   totalItem: state().vod.items,
    // });

    await this.$axios.$get(`${config.CORE_SERVICE_API}livestreaming/vod?limit=${data.limit}&page=1&sort=-start_time`).then((response) => {
      let vods = [];
      response.items.map((item, index) => {
        vods.push({
          id: item.id,
          title: item.title,
          slug: item.slug,
          banner: item.banner,
          thumbnail: item.thumbnail,
          channel: item.channel.title,
          interest: item.interest.title,
          polling: item.polling,
          startDate: +item.startTime,
          endDate: +item.endTime,
          canChatting: item.liveChat,
          requiredLogin: true,
          description: item.description,
          refEventId: item.refEventId
        });
      });

      commit("SET_VOD", {
        items: vods,
        totalItem: response.meta.totalItems,
      });

    }).catch((e) => {
      return e.response;
    });
  },

  async getScheduleLiveStreaming({commit}) {
    await this.$axios.$get(`${config.CORE_SERVICE_API}livestreaming/schedule`).then((data) => {
      commit("SET_SCHEDULES", data)
    }).catch((e) => {
      console.log(e.response);
    })
  },

  async getDetail({ commit }, uuid) {
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}articles/${uuid}`)
      .then((item) => {
        let articles = {
          ...item.data,
        };

        commit("SET_DETAIL", articles);
      })
      .catch((e) => {
        return e.response;
      });
  },
};

export default {
  state,
  mutations,
  actions,
};
