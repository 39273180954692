const state = () => ({
  otp: {
    startDate: null,
    endDate: null,
  },
  isLoading: false,
  liveActive: {
    items: [],
    loading: true
  }
});

const mutations = {
  setLoading(state, data) {
    state.isLoading = data;
  },
  setStreaming(state, data) {
    state.liveActive.items = data;
    state.liveActive.loading = false;
  },
  setOtpTime(state, data) {
    state.otp = data;
  },
};

export default {
  state,
  mutations
};
