import config from "../config";
export const state = () => ({
  totalDuration: 0,
  detail: {
    loading: true,
    items: {},
    playlist: {},
  },
  tags: {
    loading: true,
    loadingShowMore: true,
    pages: null,
    pagination: {},
    items: [],
    firstItem: {},
  },
  tags2: {
    loading: true,
    loadingShowMore: true,
    pages: null,
    items: [],
    firstItem: {},
  },
  tags3: {
    loading: true,
    loadingShowMore: true,
    pages: null,
    items: [],
    firstItem: {},
  },
  tagsShihab: {
    loading: true,
    loadingShowMore: true,
    pages: null,
    items: [],
    firstItem: {},
  },
  comments: {
    loading: true,
    items: [],
    pagination: {},
  },
  pilihan_narasi: {
    loading: true,
    items: [],
  },
  thumbnail_pilihan_narasi: {
    loading: true,
    items: {},
  },
  playlist_pilihan_narasi: {
    loading: true,
    loadingShowMore: true,
    items: [],
    pagination: {},
  },
  selanjutnya: {
    loading: true,
    items: {},
  },
  populer: {
    loading: true,
    loadingShowMore: true,
    items: [],
    totalItem: null,
  },
  autoplay: true,
  autoplayPlaylist: true,
});

export const mutations = {
  SET_AUTOPLAY(state, data) {
    state.autoplay = data;
  },
  SET_AUTOPLAY_PLAYLIST(state, data) {
    state.autoplayPlaylist = data;
  },
  SET_DURATIOPN(state, data) {
    state.totalDuration = data;
  },
  SET_DETAIL(state, data) {
    state.detail.items = data.detail ? data.detail : data;
    state.detail.playlist = data.playlist ? data.playlist : data;
    state.detail.loading = data.loading ? data.loading : false;
  },
  SET_TAGS(state, data) {
    if (data.firstLoad) {
      state.tags.items = data.tags;
    } else {
      state.tags.items = data.tags
        ? state.tags.items.length > 0
          ? state.tags.items.concat(data.tags)
          : data.tags
        : state.tags.items;
    }
    state.tags.firstItem = data.tags ? data.tags[0] : {};
    state.tags.loading = data.loading ? data.loading : false;
    state.tags.loadingShowMore = data.loadingShowMore
      ? data.loadingShowMore
      : false;
    state.tags.pages = data.pages ? data.pages : state.tags.pages;
    state.tags.pagination = data.pagination
      ? data.pagination
      : state.tags.pagination;
  },
  SET_TAGS2(state, data) {
    if (data.firstLoad) {
      state.tags2.items = data.tags2;
    } else {
      state.tags2.items = data.tags2
        ? state.tags2.items.length > 0
          ? state.tags2.items.concat(data.tags2)
          : data.tags2
        : state.tags2.items;
    }
    state.tags2.firstItem = data.tags2 ? data.tags2[0] : {};
    state.tags2.loading = data.loading ? data.loading : false;
    state.tags2.loadingShowMore = data.loadingShowMore
      ? data.loadingShowMore
      : false;
    state.tags2.pages = data.pages ? data.pages : state.tags2.pages;
  },
  SET_TAGS3(state, data) {
    if (data.firstLoad) {
      state.tags3.items = data.tags3;
    } else {
      state.tags3.items = data.tags3
        ? state.tags3.items.length > 0
          ? state.tags3.items.concat(data.tags3)
          : data.tags3
        : state.tags3.items;
    }
    state.tags3.firstItem = data.tags3 ? data.tags3[0] : {};
    state.tags3.loading = data.loading ? data.loading : false;
    state.tags3.loadingShowMore = data.loadingShowMore
      ? data.loadingShowMore
      : false;
    state.tags3.pages = data.pages ? data.pages : state.tags3.pages;
  },
  SET_TAGS_SHIHAB(state, data) {
    if (data.firstLoad) {
      state.tagsShihab.items = data.tagsShihab;
    } else {
      state.tagsShihab.items = data.tagsShihab
        ? state.tagsShihab.items.length > 0
          ? state.tagsShihab.items.concat(data.tagsShihab)
          : data.tagsShihab
        : state.tagsShihab.items;
    }
    state.tagsShihab.firstItem = data.tagsShihab ? data.tagsShihab[0] : {};
    state.tagsShihab.loading = data.loading ? data.loading : false;
    state.tagsShihab.loadingShowMore = data.loadingShowMore
      ? data.loadingShowMore
      : false;
    state.tagsShihab.pages = data.pages ? data.pages : state.tagsShihab.pages;
  },
  async SET_LIKE_DISLIKE_COMMENT(state, data) {
    if (data.commentType === "comment") {
      state.comments.items[data.indexComment].isLiked = data.isLiked;
    } else {
      state.comments.items[data.indexComment].children[
        data.indexSubComment
      ].isLiked = data.isLiked;
    }
    if (data.isLiked) {
      if (data.commentType === "comment") {
        state.comments.items[data.indexComment].likes++;
      } else {
        state.comments.items[data.indexComment].children[data.indexSubComment]
          .likes++;
      }
    } else {
      if (data.commentType === "comment") {
        state.comments.items[data.indexComment].likes--;
      } else {
        state.comments.items[data.indexComment].children[data.indexSubComment]
          .likes--;
      }
    }

    const api = this.$axios.create({
      headers: {
        Authorization: this.$axios.defaults.headers.common.Authorization,
      },
    });

    await api.$post(
      `${config.CORE_SERVICE_API}articles/${data.slug}/comments/${data.comment.id}/like`
    );
  },
  SET_COMMENTS(state, data) {
    state.comments.items = data.comments;
    state.comments.pagination = data.pagination;
    state.comments.loading = false;
  },
  SET_PILIHAN_NARASI(state, data) {
    state.pilihan_narasi.items = data;
    state.pilihan_narasi.loading = false;
  },
  SET_THUMBNAIL_PILIHAN_NARASI(state, data) {
    state.thumbnail_pilihan_narasi.items = data;
    state.thumbnail_pilihan_narasi.loading = false;
  },
  SET_PLAYLIST_PILIHAN_NARASI(state, data) {
    state.playlist_pilihan_narasi.items = data.playlist
      ? data.playlist
      : state.playlist_pilihan_narasi.items;
    state.playlist_pilihan_narasi.pagination = data.pagination
      ? data.pagination
      : state.playlist_pilihan_narasi.pagination;
    state.playlist_pilihan_narasi.loading = false;
    state.playlist_pilihan_narasi.loadingShowMore = data.loadingShowMore
      ? data.loadingShowMore
      : false;
  },
  SET_VIDEO_SELANJUTNYA(state, data) {
    state.selanjutnya.items = data;
    state.selanjutnya.loading = false;
  },
  SET_VIDEO_POPULER(state, data) {
    state.populer.items = data.video_populer
      ? data.video_populer
      : state.populer.items;
    state.populer.totalItem = data.totalItem
      ? data.totalItem
      : state.populer.totalItem;
    state.populer.loading = false;
    state.populer.loadingShowMore = data.loadingShowMore
      ? data.loadingShowMore
      : false;
  },
};

export const actions = {
  async getDetail({ commit }, data) {
    commit("SET_DETAIL", { detail: {}, playlist: {} });
    await this.$axios
      .$get(
        data.playlistSlug === ""
          ? `${config.CORE_SERVICE_API}articles/${data.articleSlug}`
          : `${config.CORE_SERVICE_API}articles/${data.articleSlug}?playlist=${
              data.playlistSlug ? data.playlistSlug : ""
            }`
      )
      .then((response) => {
        commit("SET_DETAIL", {
          detail: response.data,
          playlist: response.playlist,
        });
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getTags({ commit }, data) {
    commit("SET_TAGS", {
      loadingShowMore: true,
      tags: [],
    });
    await this.$axios
      .$get(
        `${config.CORE_SERVICE_API}tags/${data.tagsSlug}?page=${
          data.page
        }&limit=${data.limit ? data.limit : 10}`
      )
      .then((response) => {
        commit("SET_TAGS", {
          tags: response.data,
          pages: response.meta.pages,
          pagination: response.meta,
          firstLoad: data.firstLoad ? true : false,
        });
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getTags2({ commit }, data) {
    commit("SET_TAGS2", {
      loadingShowMore: true,
      tags2: [],
    });
    await this.$axios
      .$get(
        `${config.CORE_SERVICE_API}tags/${data.tagsSlug}?page=${
          data.page
        }&limit=${data.limit ? data.limit : 10}`
      )
      .then((response) => {
        commit("SET_TAGS2", {
          tags2: response.data,
          pages: response.meta.pages,
          firstLoad: data.firstLoad ? true : false,
        });
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getTags3({ commit }, data) {
    commit("SET_TAGS3", {
      loadingShowMore: true,
      tags3: [],
    });
    await this.$axios
      .$get(
        `${config.CORE_SERVICE_API}tags/${data.tagsSlug}?page=${
          data.page
        }&limit=${data.limit ? data.limit : 10}`
      )
      .then((response) => {
        commit("SET_TAGS3", {
          tags3: response.data,
          pages: response.meta.pages,
          firstLoad: data.firstLoad ? true : false,
        });
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getComments({ commit }, data) {
    await this.$axios
      .$get(
        `${config.CORE_SERVICE_API}articles/${data.articleSlug}/comments?page=${
          data.page ? data.page : 1
        }&limit=${data.limit ? data.limit : 10}&sort=${
          data.sort ? data.sort : ""
        }`
      )
      .then((response) => {
        commit("SET_COMMENTS", {
          comments: response.data,
          pagination: response.meta,
        });
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getPilihanNarasi({ commit }) {
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}playlists`)
      .then((response) => {
        commit("SET_PILIHAN_NARASI", response.data);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getThumbnailPilihanNarasi({ commit }, slug) {
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}playlists/${slug}`)
      .then((response) => {
        commit("SET_THUMBNAIL_PILIHAN_NARASI", response.data);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getPlaylistPilihanNarasi({ commit }, data) {
    commit("SET_PLAYLIST_PILIHAN_NARASI", {
      loadingShowMore: true,
    });
    await this.$axios
      .$get(
        `${config.CORE_SERVICE_API}playlists/${data.slug}/items?page=${
          data.page
        }&limit=${data.limit ? data.limit : 10}`
      )
      .then((response) => {
        commit("SET_PLAYLIST_PILIHAN_NARASI", {
          playlist: response.data,
          pagination: response.meta,
        });
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getVideoPopuler({ commit }, data) {
    commit("SET_VIDEO_POPULER", {
      loadingShowMore: true,
    });
    await this.$axios
      .$get(
        `${config.CORE_SERVICE_API}articles?sort=publishDate&dir=DESC&limit=${data.limit}&popular=true`
      )
      .then((response) => {
        commit("SET_VIDEO_POPULER", {
          video_populer: response.data,
          totalItem: response.meta.total,
        });
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getVideoSelanjutnya({ commit }, data) {
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}articles/${data.articleSlug}/next`)
      .then((response) => {
        commit("SET_VIDEO_SELANJUTNYA", response.data);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
};
