import config from "../config";
const state = () => ({
  isLoading: true,
  recapitulations: [],
  recapitulationsByCity: null,
  recapitulationsByParpol: [],
  tagCloud: [
    ["korupsi", 89],
    ["penegakkan hukum", 21],
    ["ekonomi", 7],
    ["lingkungan", 29],
    ["teknologi", 2],
    ["pendidikan", 12],
    ["kesehatan", 6],
    ["pembangunan infrastruktur", 28],
  ],
  highlights: {
    loading: true,
    items: [],
    pagination: {},
  },
  caleg: {
    loading: true,
    items: [],
    pagination: {},
  },
  newsKpu: {
    loading: true,
    items: [],
    pagination: {},
  },
  terkini: {
    loading: true,
    loadingShowMore: true,
    items: [],
    pages: null,
  },
  terpopuler: {
    loading: true,
    loadingShowMore: true,
    items: [],
    pages: null,
  },
});

const mutations = {
  SET_LOADING(state, data) {
    state.isLoading = data;
  },
  SET_RECAPITULATIONS(state, data) {
    state.recapitulations = data;
  },
  SET_RECAPITULATIONS_CITY(state, data) {
    state.recapitulationsByCity = data;
  },
  SET_RECAPITULATIONS_PARPOL(state, data) {
    state.recapitulationsByParpol = data;
  },
  SET_HIGHLIGHT(state, data) {
    state.highlights = data;
  },
  SET_CALEG(state, data) {
    state.caleg.items = data.data;
    state.caleg.pagination = data.pagination;
    state.caleg.loading = false;
  },
  SET_KPU(state, data) {
    state.newsKpu = data;
  },
  SET_TERKINI(state, data) {
    if (data.firstLoad) {
      state.terkini.items = data.data;
    } else {
      state.terkini.items = data.data
        ? state.terkini.items.length > 0
          ? state.terkini.items.concat(data.data)
          : data.data
        : state.terkini.items;
    }

    state.terkini.pages = data.pages ? data.pages : state.terkini.pages;
    state.terkini.loading = false;
    state.terkini.loadingShowMore = data.loadingShowMore
      ? data.loadingShowMore
      : false;
  },
  SET_POPULER(state, data) {
    if (data.firstLoad) {
      state.terpopuler.items = data.data;
    } else {
      state.terpopuler.items = data.data
        ? state.terpopuler.items.length > 0
          ? state.terpopuler.items.concat(data.data)
          : data.data
        : state.terpopuler.items;
    }

    state.terpopuler.pages = data.pages ? data.pages : state.terpopuler.pages;
    state.terpopuler.loading = false;
    state.terpopuler.loadingShowMore = data.loadingShowMore
      ? data.loadingShowMore
      : false;
  },
};

const actions = {
  async getRecapitulations({ commit }) {
    commit('SET_LOADING', true);
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}quick-count/capres`)
      // .$get(`https://storage.googleapis.com/narasi-non-production.appspot.com/test/recapitulations.json`)
      .then((response) => {
        commit('SET_LOADING', false);
        commit("SET_RECAPITULATIONS", response);
      })
      .catch((e) => {
        commit('SET_LOADING', false);
        return e.response;
      });
  },
  async getRecapitulationsByCity({ commit }, provider) {
    commit('SET_LOADING', true);
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}quick-count/provider/${provider}`)
      // .$get(`https://storage.googleapis.com/narasi-non-production.appspot.com/test/city.json`)
      .then((response) => {
        commit('SET_LOADING', false);
        commit("SET_RECAPITULATIONS_CITY", response);
      })
      .catch((e) => {
        commit('SET_LOADING', false);
        return e.response;
      });
  },
  async getRecapitulationsByParpol({ commit }, provider) {
    commit('SET_LOADING', true);
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}quick-count/parpol/${provider}`)
      // .$get(`https://storage.googleapis.com/narasi-non-production.appspot.com/test/parpol.json`)
      .then((response) => {
        commit('SET_LOADING', false);
        commit("SET_RECAPITULATIONS_PARPOL", response);
      })
      .catch((e) => {
        commit('SET_LOADING', false);
        return e.response;
      });
  },
  async getArticlesByTags({ commit }, data) {
    await this.$axios
      .$get(
        `${config.CORE_SERVICE_API}articles?sort=publishDate&dir=DESC&topics=${
          data.tagsSlug
        }&page=${data.page}&limit=${data.limit ? data.limit : 10}`
      )
      .then((response) => {
        let items = response.data.map((article) => {
          const type = article.isVideo && !article.isText ? "video" : "read";
          return {
            title: article.title,
            slug: `/${type}/${article.channel.slug}/${article.slug}`,
            short: article.short,
            type: type,
            timeVideo: article.timeVideo,
            thumbnail: article.thumbnail.medium,
          };
        });

        commit("SET_HIGHLIGHT", {
          loading: false,
          items: items,
          pagination: response.meta,
        });
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getCaleg({ commit }, data) {
    let endpoint = `${config.CORE_SERVICE_API}thirdparty/rekam_jejak?limit=24&page=${data.page}`;

    if (data.search) {
      window.$nuxt.$store.commit("config/setLoading", true);
      endpoint = `${config.CORE_SERVICE_API}thirdparty/rekam_jejak?limit=8&page=${data.page}&search=${data.search}`;
    }
    await this.$axios
      .$get(endpoint)
      .then((response) => {
        commit("SET_CALEG", {
          data: response.data,
          pagination: response.meta,
        });
      })
      .catch((e) => {
        return e.response;
      });

      window.$nuxt.$store.commit("config/setLoading", false);
  },
  async getArticlesByKPU({ commit }, data) {
    // https://stg-api.narasi.co.id/api/articles?sort=publishDate&dir=DESC&limit=5&page=1&isText=true&topics=komisi-pemilihan-umum,kpu
    await this.$axios
      .$get(
        `${config.CORE_SERVICE_API}articles?sort=publishDate&dir=DESC&topics=${
          data.tagsSlug
        }&page=${data.page}&limit=${data.limit ? data.limit : 10}`
      )
      .then((response) => {
        let items = response.data.map((article) => {
          const type = article.isVideo && !article.isText ? "video" : "read";
          return {
            title: article.title,
            slug: `/${type}/${article.channel.slug}/${article.slug}`,
            short: article.short,
            type: type,
            timeVideo: article.timeVideo,
            thumbnail: article.thumbnail.medium,
          };
        });

        commit("SET_KPU", {
          loading: false,
          items: items,
          pagination: response.meta,
        });
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getArticlesByTerkini({ commit }, data) {
    // https://stg-api.narasi.co.id/api/articles?sort=publishDate&dir=DESC&limit=5&page=1&isText=true&topics=suara-penentu
    commit("SET_TERKINI", {
      loadingShowMore: true,
      data: [],
    });

    await this.$axios
      .$get(
        `${config.CORE_SERVICE_API}articles?sort=publishDate&dir=DESC&topics=${
          data.tagsSlug
        }&page=${data.page}&limit=${data.limit ? data.limit : 10}`
      )
      .then((response) => {
        let items = response.data.map((article) => {
          const type = article.isVideo && !article.isText ? "video" : "read";
          return {
            title: article.title,
            slug: `/${type}/${article.channel.slug}/${article.slug}`,
            short: article.short,
            type: type,
            timeVideo: article.timeVideo,
            thumbnail: article.thumbnail.medium,
          };
        });
        commit("SET_TERKINI", {
          data: items,
          pages: response.meta.pages,
          firstLoad: data.firstLoad ? true : false,
        });
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getArticlesByTerpopuler({ commit }, data) {
    // https://stg-api.narasi.co.id/api/articles?sort=publishDate&dir=DESC&limit=5&page=1&isText=true&topics=suara-penentu
    commit("SET_POPULER_LOADING", {
      loadingShowMore: true,
      data: [],
    });

    await this.$axios
      .$get(
        `${
          config.CORE_SERVICE_API
        }articles?sort=publishDate&popular=true&dir=DESC&topics=${
          data.tagsSlug
        }&page=${data.page}&limit=${data.limit ? data.limit : 10}`
      )
      .then((response) => {
        let items = response.data.map((article) => {
          const type = article.isVideo && !article.isText ? "video" : "read";
          return {
            title: article.title,
            slug: `/${type}/${article.channel.slug}/${article.slug}`,
            short: article.short,
            type: type,
            timeVideo: article.timeVideo,
            thumbnail: article.thumbnail.medium,
          };
        });
        commit("SET_POPULER", {
          data: items,
          pages: response.meta.pages,
          firstLoad: data.firstLoad ? true : false,
        });
      })
      .catch((e) => {
        return e.response;
      });
  },
};

export default {
  state,
  mutations,
  actions,
};
