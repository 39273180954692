import config from "../config";
const state = () => ({
  community: {
    loading: true,
    items: []
  },
});

const mutations = {
  SET_PINNED(state, data) {
    state.pinned.items = data;
    state.pinned.loading = false;
  },
}

const actions = {
  async pinned({commit}, maximal) {
    await this.$axios.$get(`${config.CORE_SERVICE_API}articles?limit=${maximal}&sort=publishDate&dir=DESC`).then((response) => {
      let articles = [];
      response.data.map((item, index) => {
        articles.push({
          channelLogo: item.channel.logo,
          channelTitle: item.channel.title,
          channelSlug: item.channel.slug,
          categoryLogo: item.category ? item.category.logo : null,
          categoryTitle: item.category ? item.category.title : null,
          categorySlug: item.category ? item.category.slug : null,
          articleSlug: item.slug,
          articleTitle: item.title,
          articleShort: item.short,
          contentUrl: {
            default: item.contentUrl.default,
            content: item.contentUrl.content,
          },
          thumbnail: {
            large: item.thumbnail.large,
            medium: item.thumbnail.medium,
            small: item.thumbnail.small,
          },
          publishDate: item.publishDate,
          createdAt: item.createdAt,
          isVideo: item.isVideo,
          videoTime: item.timeVideo,
          banner: item.banner,
        })
      })

      commit('SET_PINNED', articles)
      return response;
    }).catch((e) => {
      return e.response;
    });
  },
}

export default {
  state,
  mutations,
  actions
};
