import config from "../config";

export const state = () => ({
  academyBanner: {
    loading: true,
    items: [],
  },
  academyClass: {
    loading: true,
    items: [],
  },
  academyMentor: {
    loading: true,
    items: [],
  },
  academyTestimoni: {
    loading: true,
    items: [],
  },
  academyFAQ: {
    loading: true,
    items: [],
  },
  academyAll: {
    loading: true,
    items: [],
  },
  academyKarya: {
    loading: true,
    items: [],
  },
  academyByCategory: {
    loading: true,
    items: [],
  },
  academyDetailClass: {
    loading: true,
    items: {},
  },
  academyDetailMentor: {
    loading: true,
    items: {},
  },
  academyDetailKarya: {
    loading: true,
    items: {},
  },
});

export const mutations = {
  SET_BANNER(state, data) {
    state.academyBanner.items = data;
    state.academyBanner.loading = false;
  },
  SET_CLASS(state, data) {
    state.academyClass.items = data;
    state.academyClass.loading = false;
  },
  SET_MENTOR(state, data) {
    state.academyMentor.items = data;
    state.academyMentor.loading = false;
  },
  SET_TESTIMONI(state, data) {
    state.academyTestimoni.items = data;
    state.academyTestimoni.loading = false;
  },
  SET_FAQ(state, data) {
    state.academyFAQ.items = data;
    state.academyFAQ.loading = false;
  },
  SET_ALL_DATA(state, data) {
    state.academyAll.items = data;
    state.academyAll.loading = false;
  },
  SET_KARYA_ACADEMY(state, data) {
    state.academyKarya.items = data;
    state.academyKarya.loading = false;
  },
  SET_ACADEMY_BY_CATEGORY(state, data) {
    state.academyByCategory.items = data;
    state.academyByCategory.loading = false;
  },
  SET_DETAIL_CLASS(state, data) {
    state.academyDetailClass.items = data;
    state.academyDetailClass.loading = false;
  },
  SET_DETAIL_MENTOR(state, data) {
    state.academyDetailMentor.items = data;
    state.academyDetailMentor.loading = false;
  },
  SET_DETAIL_KARYA_ACADEMY(state, data) {
    state.academyDetailKarya.items = data;
    state.academyDetailKarya.loading = false;
  },
};

export const actions = {
  async getBanner({ commit }) {
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}academy/banners`)
      .then((response) => {
        commit("SET_BANNER", response.data);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getClass({ commit }) {
    await this.$axios
      .$get(`${config.ACADEMY_SERVICE_API}class`)
      .then((response) => {
        commit("SET_CLASS", response.data);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getDetailClass({ commit }, data) {
    await this.$axios
      .$get(`${config.ACADEMY_SERVICE_API}class/${data.slug}`)
      .then((response) => {
        commit("SET_DETAIL_CLASS", response.data);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getMentor({ commit }) {
    await this.$axios
      // .$get(`${config.ACADEMY_SERVICE_API}speakers`)
      .$get(
        `${config.ACADEMY_SERVICE_API}speakers?sort=name&dir=ASC&limit=1000`
      )
      .then((response) => {
        commit("SET_MENTOR", response.data);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getTestimoni({ commit }) {
    await this.$axios
      .$get(`${config.ACADEMY_SERVICE_API}testimonies`)
      .then((response) => {
        commit("SET_TESTIMONI", response.data);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getFAQ({ commit }, limit) {
    await this.$axios
      .$get(
        `${config.ACADEMY_SERVICE_API}faq?page=1&limit=${limit}&sort=order&dir=ASC`
      )
      .then((response) => {
        commit("SET_FAQ", response.data);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getAll({ commit }) {
    await this.$axios
      .$get(`https://api-mock-staging.narasi.media/api/academies`)
      .then((response) => {
        let all = [];
        response.data.map((item, index) => {
          all.push({
            id: item.id,
            eventStatus: {
              id: item.eventStatus.id,
              name: item.eventStatus.name,
              slug: item.eventStatus.slug,
            },
            imageThumbnail: item.imageThumbnail,
            imageBanner: item.imageBanner,
            classStatus: item.classStatus,
            category: {
              id: item.category.id,
              name: item.category.name,
              slug: item.category.slug,
            },
            classType: {
              id: item.classType.id,
              name: item.classType.name,
              slug: item.classType.slug,
            },
            location: item.location,
            organizer: item.organizer,
            title: item.title,
            slug: item.slug,
            description: item.description,
            mentor: item.mentor,
            introductionVideo: item.introductionVideo,
            introductionDetail: item.introductionDetail,
            playlistVideo: item.playlistVideo,
            date: item.date,
            price: item.price,
            discount: item.discount,
            kuota: item.kuota,
            createdAt: item.createdAt,
          });
        });

        commit("SET_ALL_DATA", all);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getKaryaAcademy({ commit }) {
    await this.$axios
      .$get(`https://api-mock-staging.narasi.media/api/academies/karya-academy`)
      .then((response) => {
        let karya_academy = [];
        response.data.map((item, index) => {
          karya_academy.push({
            id: item.id,
            image: item.image,
            name: item.name,
            batch: item.batch,
            title: item.title,
            slug: item.slug,
            description: item.description,
            createdAt: item.createdAt,
          });
        });

        commit("SET_KARYA_ACADEMY", karya_academy);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getAcademyByCategory({ commit }, data) {
    let category = {};
    await this.$axios
      .$get(
        `https://api-mock-staging.narasi.media/api/academies/class-categories`
      )
      .then((response) => {
        response.data.map((item, index) => {
          if (item.slug === data.slug) {
            category = {
              id: item.id,
              name: item.name,
              slug: item.slug,
            };
          }
        });
        return response;
      })
      .catch((e) => {
        return e.response;
      });
    await this.$axios
      .$get(`https://api-mock-staging.narasi.media/api/academies`)
      .then((response) => {
        let academies = [];
        response.data.map((item, index) => {
          if (item.category.id === category.id) {
            academies.push({
              id: item.id,
              eventStatus: {
                id: item.eventStatus.id,
                name: item.eventStatus.name,
                slug: item.eventStatus.slug,
              },
              image: item.imageThumbnail,
              ticketType: item.ticketType,
              category: {
                id: item.category.id,
                name: item.category.name,
                slug: item.category.slug,
              },
              classType: {
                id: item.classType.id,
                name: item.classType.name,
                slug: item.classType.slug,
              },
              location: item.location,
              organizer: item.organizer,
              title: item.title,
              slug: item.slug,
              description: item.description,
              date: item.date,
              price: item.price,
              discount: item.discount,
              createdAt: item.createdAt,
            });
          } else if (category.id === 1) {
            academies.push({
              id: item.id,
              eventStatus: {
                id: item.eventStatus.id,
                name: item.eventStatus.name,
                slug: item.eventStatus.slug,
              },
              image: item.imageThumbnail,
              ticketType: item.ticketType,
              category: {
                id: item.category.id,
                name: item.category.name,
                slug: item.category.slug,
              },
              classType: {
                id: item.classType.id,
                name: item.classType.name,
                slug: item.classType.slug,
              },
              location: item.location,
              organizer: item.organizer,
              title: item.title,
              slug: item.slug,
              description: item.description,
              date: item.date,
              price: item.price,
              discount: item.discount,
              createdAt: item.createdAt,
            });
          }
        });

        commit("SET_ACADEMY_BY_CATEGORY", academies);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getDetailMentor({ commit }, data) {
    await this.$axios
      .$get(`https://api-mock-staging.narasi.media/api/academies/mentors`)
      .then((response) => {
        let detail_mentor = {};
        response.data.map((item, index) => {
          if (data.slug === item.slug) {
            detail_mentor.id = item.id;
            detail_mentor.name = item.name;
            detail_mentor.slug = item.slug;
            detail_mentor.profession = item.profession;
            detail_mentor.about = item.about;
            detail_mentor.image = item.image;
            detail_mentor.imageMobile = item.imageMobile;
            detail_mentor.createdAt = item.createdAt;
          }
        });
        commit("SET_DETAIL_MENTOR", detail_mentor);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getDetailKaryaAcademy({ commit }, data) {
    await this.$axios
      .$get(`https://api-mock-staging.narasi.media/api/academies/karya-academy`)
      .then((response) => {
        let detail_karya_academy = {};
        response.data.map((item, index) => {
          if (data.slug === item.slug) {
            detail_karya_academy.id = item.id;
            detail_karya_academy.image = item.image;
            detail_karya_academy.name = item.name;
            detail_karya_academy.batch = item.batch;
            detail_karya_academy.title = item.title;
            detail_karya_academy.slug = item.slug;
            detail_karya_academy.description = item.description;
            detail_karya_academy.createdAt = item.createdAt;
          }
        });
        commit("SET_DETAIL_KARYA_ACADEMY", detail_karya_academy);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
};
