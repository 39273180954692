import config from "../config";
const state = () => ({
  programs: {
    loading: true,
    items: [],
  },
  program: {
    loading: true,
    items: {},
  },
  allProgram: {
    loading: true,
    loadingShowMore: true,
    pages: null,
    items: [],
  },
  home: {
    loading: true,
    items: {},
  },
  videos: {
    loading: true,
    items: {},
    pagination: {},
  },
  playlists: {
    loading: true,
    items: {},
  },
});

const mutations = {
  SET_PROGRAMS(state, data) {
    state.programs.items = data;
    state.programs.loading = false;
  },
  SET_ALL_PROGRAM(state, data) {
    // state.allProgram.items = data.program
    //   ? data.program
    //   : state.allProgram.items;
    if (data.firstLoad) {
      state.allProgram.items = data.program;
    } else {
      state.allProgram.items = data.program
        ? state.allProgram.items.length > 0
          ? state.allProgram.items.concat(data.program)
          : data.program
        : state.allProgram.items;
    }

    state.allProgram.pages = data.pages ? data.pages : state.allProgram.pages;
    state.allProgram.loading = false;
    state.allProgram.loadingShowMore = data.loadingShowMore
      ? data.loadingShowMore
      : false;
  },
  SET_PROGRAM(state, data) {
    state.program.items = data;
    state.program.loading = false;
  },
  SET_HOME(state, data) {
    state.home.items = data;
    state.home.loading = false;
  },
  SET_VIDEOS(state, data) {
    state.videos.items = data.items;
    state.videos.pagination = data.pagination;
    state.videos.loading = false;
  },
  SET_PLAYLISTS(state, data) {
    state.playlists.items = data;
    state.playlists.loading = false;
  },
};

const actions = {
  async getPrograms({ commit }) {
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}programs`)
      .then((response) => {
        let programs_ = [];
        response.data.map((item, index) => {
          programs_.push({
            id: item.id,
            title: item.title,
            slug: item.slug,
            logo: item.logo,
            thumbnail: item.thumbnail,
            description: item.description,
            activated: item.activated,
            order: item.order,
            createdAt: item.createdAt,
            createdBy: item.createdBy,
            updatedAt: item.updatedAt,
            updatedBy: item.updatedBy,
            channels: item.channels,
          });
        });
        commit("SET_PROGRAMS", programs_);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getAllPrograms({ commit }, data) {
    commit("SET_ALL_PROGRAM", {
      loadingShowMore: true,
      program: [],
    });
    await this.$axios
      .$get(
        `${config.CORE_SERVICE_API}programs/${
          data.slug && data.slug != "semua" ? data.slug : "all"
        }?limit=${data.limit ? data.limit : 5}&page=${
          data.page ? data.page : 1
        }`
      )
      .then((response) => {
        commit("SET_ALL_PROGRAM", {
          program: response.data,
          pages: response.meta.pages,
          firstLoad: data.firstLoad ? true : false,
        });
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getProgram({ commit }, slug) {
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}programs/${slug}`)
      .then((response) => {
        let program_ = {
          id: response.data.id,
          title: response.data.title,
          slug: response.data.slug,
          logo: response.data.logo,
          thumbnail: response.data.thumbnail,
          description: response.data.description,
          activated: response.data.activated,
          order: response.data.order,
          createdAt: response.data.createdAt,
          createdBy: response.data.createdBy,
          updatedAt: response.data.updatedAt,
          updatedBy: response.data.updatedBy,
          channels: response.data.channels,
        };
        commit("SET_PROGRAM", program_);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getHome({ commit }, slug) {
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}channels/${slug}/home`)
      .then((response) => {
        let home_ = {
          channel: response.data.channel,
          article: response.data.article,
          playlists: response.data.playlists,
        };
        commit("SET_HOME", home_);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getVideos({ commit }, data) {
    await this.$axios
      .$get(
        `${config.CORE_SERVICE_API}channels/${data.slug}/videos?page=${
          data.page ? data.page : 1
        }`
      )
      .then((response) => {
        let videos_ = {
          channel: response.data.channel,
          videos: response.data.videos,
        };
        commit("SET_VIDEOS", { items: videos_, pagination: response.meta });
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getPlaylists({ commit }, slug) {
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}channels/${slug}/playlists`)
      .then((response) => {
        let playlists_ = {
          channel: response.data.channel,
          playlists: response.data.playlists,
        };
        commit("SET_PLAYLISTS", playlists_);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
};

export default {
  state,
  mutations,
  actions,
};
