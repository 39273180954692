import config from "../config";

export const state = () => ({
  device: {
    isDesktop: false,
  },
  home_banner: {
    loading: true,
    items: {},
  },
  interest: {
    loading: true,
    items: [],
  },
  interest_list: {
    loading: true,
    items: [],
    pagination: {},
  },
  pinned: {
    loading: true,
    items: [],
  },
  untukmu: {
    loading: true,
    items: [],
  },
  untukmu_by_interest: {
    loading: true,
    items: [],
  },
  articles_highlight: {
    loading: true,
    items: {},
  },
  articles: {
    loading: true,
    items: [],
  },
  terpopuler: {
    loading: true,
    items: [],
    pagination: {},
  },
  terpopulerVideo: {
    loading: true,
    items: [],
    pagination: {},
  },
  terpopulerText: {
    loading: true,
    items: [],
    pagination: {},
  },
  pilihan_narasi: {
    loading: true,
    items: [],
  },
  boosted_content: {
    loading: true,
    items: [],
  },
});

export const mutations = {
  SET_DEVICE(state, data) {
    state.device.isDesktop = data;
  },
  SET_HOME_BANNER(state, data) {
    state.home_banner.items = data;
    state.home_banner.loading = false;
  },
  SET_INTEREST(state, data) {
    state.interest.items = data;
    state.interest.loading = false;
  },
  SET_INTEREST_LIST(state, data) {
    state.interest_list.items = data.data;
    state.interest_list.pagination = data.pagination;
    state.interest_list.loading = false;
  },
  SET_PINNED(state, data) {
    state.pinned.items = data;
    state.pinned.loading = false;
  },
  SET_UNTUKMU(state, data) {
    state.untukmu.items = data;
    state.untukmu.loading = false;
  },
  SET_UNTUKMU_BY_INTEREST(state, data) {
    state.untukmu_by_interest.items = data;
    state.untukmu_by_interest.loading = false;
  },
  SET_ARTICLES_HIGHLIGHT(state, data) {
    state.articles_highlight.items = data;
    state.articles_highlight.loading = false;
  },
  SET_ARTICLES(state, data) {
    state.articles.items = data;
    state.articles.loading = false;
  },
  SET_TERPOPULER(state, data) {
    state.terpopuler.items = data.data;
    state.terpopuler.pagination = data.pagination;
    state.terpopuler.loading = false;
  },
  SET_TERPOPULER_VIDEO(state, data) {
    state.terpopulerVideo.items = data.data;
    state.terpopulerVideo.pagination = data.pagination;
    state.terpopulerVideo.loading = false;
  },
  SET_TERPOPULER_TEXT(state, data) {
    state.terpopulerText.items = data.data;
    state.terpopulerText.pagination = data.pagination;
    state.terpopulerText.loading = false;
  },
  SET_PILIHAN_NARASI(state, data) {
    state.pilihan_narasi.items = data;
    state.pilihan_narasi.loading = false;
  },
  SET_BOOSTED_CONTENT(state, data) {
    state.boosted_content.items = data;
    state.boosted_content.loading = false;
  },
};

export const actions = {
  async getHomeBanner({ commit }) {
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}articles/home-banner`)
      .then((response) => {
        commit("SET_HOME_BANNER", response.data);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async setInterest({ commit }) {
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}interests?limit=1000&page=1`)
      .then((response) => {
        commit("SET_INTEREST", response.data);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async setInterestList({ commit }, data) {
    await this.$axios
      .$get(
        `${config.CORE_SERVICE_API}articles?category=${
          data.slug == "all" ? "" : data.slug
        }&page=${data.page ? data.page : 1}`
      )
      .then((response) => {
        commit("SET_INTEREST_LIST", {
          data: response.data,
          pagination: response.meta,
        });
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getPinned({ commit }) {
    await this.$axios
      .$get(
        `${config.CORE_SERVICE_API}articles?sort=publishDate&dir=DESC&limit=8&sticky=true`
      )
      .then((response) => {
        commit("SET_PINNED", response.data);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getUntukmu({ commit }) {
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}articles/for-you`)
      .then((response) => {
        commit("SET_UNTUKMU", response.data);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getUntukmuByInterest({ commit }) {
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}articles/for-you/group`)
      .then((response) => {
        commit("SET_UNTUKMU_BY_INTEREST", response.data);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getArticlesHighlight({ commit }) {
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}articles/highlight`)
      .then((response) => {
        commit("SET_ARTICLES_HIGHLIGHT", response.data);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getArticles({ commit }) {
    await this.$axios
      .$get(
        `${config.CORE_SERVICE_API}articles?sort=publishDate&dir=DESC&isText=true&page=1&limit=7&offset=1`
      )
      .then((response) => {
        commit("SET_ARTICLES", response.data);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getTerpopuler({ commit }, data) {
    const keys = Object.keys(data);
    let queryParam = `${keys[0]}=${data[keys[0]]}`;
    if (keys.length === 2) {
      queryParam = `${keys[0]}=${data[keys[0]]}&${keys[1]}=${data[keys[1]]}`;
    }

    await this.$axios
      .$get(
        `${config.CORE_SERVICE_API}articles?sort=publishDate&dir=DESC&${queryParam}&limit=10&popular=true`
      )
      .then((response) => {
        commit("SET_TERPOPULER", {
          data: response.data,
          pagination: response.meta,
        });
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getTerpopulerVideo({ commit }) {
    await this.$axios
      .$get(
        `${config.CORE_SERVICE_API}articles?sort=publishDate&dir=DESC&limit=7&popular=true&page=1&isVideo=true`
      )
      .then((response) => {
        commit("SET_TERPOPULER_VIDEO", {
          data: response.data,
          pagination: response.meta,
        });
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getTerpopulerText({ commit }) {
    await this.$axios
      .$get(
        `${config.CORE_SERVICE_API}articles?sort=publishDate&dir=DESC&limit=7&popular=true&page=1&isText=true`
      )
      .then((response) => {
        commit("SET_TERPOPULER_TEXT", {
          data: response.data,
          pagination: response.meta,
        });
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getPilihanNarasi({ commit }) {
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}playlists`)
      .then((response) => {
        commit("SET_PILIHAN_NARASI", response.data);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getBoostedContent({ commit }) {
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}articles/boosted`)
      .then((response) => {
        let boosted_content = [];
        response.data.map((item, index) => {
          if (item.article) {
            boosted_content.push(item.article);
          }
        });
        commit("SET_BOOSTED_CONTENT", boosted_content);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
};
