import config from "../config";
export const state = () => ({
  interest: {
    loading: true,
    items: [],
  },
  pagination: {
    loading: true,
    items: {},
  },
  result: {
    loading: true,
    items: [],
    channels: [],
    topics: [],
    pagination: {},
  },
  popular: {
    loading: true,
    items: [],
    pagination: {},
  },
  autocompleteResult: {
    loading: true,
    items: [],
  },
  topics: {
    loading: true,
    items: [],
  },
});

export const mutations = {
  SET_SPONSOR(state, data) {
    state.topics.items = data;
    state.topics.loading = false;
  },
  SET_AUTOCOMPLETE(state, data) {
    state.autocompleteResult.items = data;
    state.autocompleteResult.loading = false;
  },
  SET_POPULAR(state, data) {
    state.popular.items = data.result;
    state.popular.pagination = data.pagination;
    state.popular.loading = false;
  },
  SET_PAGINATION(state, data) {
    state.pagination.items = data;
    state.pagination.loading = false;
  },
  SET_RESULT(state, data) {
    state.result.topics = data.topics;
    state.result.channels = data.channels;
    state.result.items = data.result;
    state.result.pagination = data.pagination;
    state.result.loading = false;
  },
};

export const actions = {
  async getSponsorTopics({ commit }, search) {
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}search/topics?search=${search}`)
      .then((response) => {
        let topics = [];
        response.data.map((item, index) => {
          topics.push({
            id: item.id,
            topic: item.topic,
            isSponsor: item.isSponsor,
          });
        });
        commit("SET_SPONSOR", topics);
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getPopuler({ commit }, data) {
    await this.$axios
      .$get(
        `${config.CORE_SERVICE_API}search/popular?limit=${data.limit}&page=${
          data.page ? data.page : 1
        }&sort=searchCount&dir=DESC`
      )
      .then((response) => {
        let result_ = [];
        response.data.rows.map((item, index) => {
          result_.push({
            id: item.id,
            title: item.title,
            slug: item.slug,
            short: item.short,
            isVideo: item.isVideo,
            isText: item.isText,
            content: item.content,
            contentUrl: item.contentUrl,
            timeVideo: item.timeVideo,
            thumbnail: item.thumbnail,
            mustWatch: item.mustWatch,
            popular: item.popular,
            sticky: item.sticky,
            visitCount: item.visitCount,
            publishDate: item.publishDate,
            publishStatus: item.publishStatus,
            createdAt: item.createdAt,
            createdBy: item.createdBy,
            updatedAt: item.updatedAt,
            updatedBy: item.updatedBy,
            category: item.category,
            channel: item.channel,
          });
        });

        let pagination_ = {};
        pagination_.total = response.data.meta.total;
        pagination_.pages = response.data.meta.pages;
        pagination_.page = response.data.meta.page;
        pagination_.limit = response.data.meta.limit;
        pagination_.offset = response.data.meta.offset;
        commit("SET_POPULAR", {
          result: result_,
          pagination: pagination_,
        });
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },
  async getResult({ commit }, data) {
    await this.$axios
      .$get(
        `${config.CORE_SERVICE_API}articles?title=${data.search}&limit=${data.limit}&page=${data.page}&dir=${data.sort}&sort=publishDate`
      )
      .then((response) => {
        let channels = ["Semua"];
        let topics = [];
        let result_ = [];
        response.data.map((item, index) => {
          if (
            channels.filter((channel) => channel === item.channel.title)
              .length === 0
          ) {
            channels.push(item.channel.title);
          }

          if (item.topics) {
            const tags = JSON.parse(item.topics);
            tags.map((tag) => {
              if (
                topics.filter((topic) => topic.topic === tag.topic).length === 0
              ) {
                topics.push({
                  id: tag.id,
                  topic: tag.topic,
                  isSponsor: false,
                });
              }
            });
          }

          result_.push({
            id: item.id,
            title: item.title,
            slug: item.slug,
            short: item.short,
            isVideo: item.isVideo,
            isText: item.isText,
            content: item.content,
            contentUrl: item.contentUrl,
            timeVideo: item.timeVideo,
            thumbnail: item.thumbnail,
            mustWatch: item.mustWatch,
            popular: item.popular,
            sticky: item.sticky,
            visitCount: item.visitCount,
            publishDate: item.publishDate,
            publishStatus: item.publishStatus,
            createdAt: item.createdAt,
            createdBy: item.createdBy,
            updatedAt: item.updatedAt,
            updatedBy: item.updatedBy,
            category: item.category,
            channel: item.channel,
          });
        });
        let pagination_ = {};
        pagination_.total = response.meta.total;
        pagination_.pages = response.meta.pages;
        pagination_.page = response.meta.page;
        pagination_.limit = response.meta.limit;
        pagination_.offset = response.meta.offset;
        commit("SET_RESULT", {
          topics: topics,
          channels: channels,
          result: result_,
          pagination: pagination_,
        });
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  },

  async autocomplete({ commit }, q) {
    await this.$axios
      .$get(`${config.CORE_SERVICE_API}search/autocomplete?search=${q}`)
      .then((response) => {
        let data = [];
        response.data.map((item, i) => {
          data.push({
            id: item.id,
            title: item.title,
            slug: item.slug,
            searchCount: item.searchCount,
            channel: item.channel,
            isText: item.isText,
          });
        });
        commit("SET_AUTOCOMPLETE", data);
      })
      .catch((e) => {
        return e.response;
      });
  },
};
